import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  Alert,
  Autocomplete,
  Button,
  Paper,
  Select,
  TextField,
} from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { useStyles } from '../styles';
import { CreateProductionsValidation } from './ProductionsValidationSchema';
import ProductionsSideDrawers from './ProductionsSideDrawers';
import { SideBarState } from '../types';
import {
  deleteMedia,
  uploadMediaMultiple,
  uploadMediaSingle,
  uploadMediaSingleVideo,
  useDeleteProduction,
  useGetProduction,
  usePostProduction,
  usePutProduction,
} from '../../../api/production';
import Details from '../../common/Details';
import { useGetAllStudios } from '../../../api/studios';
import { useGetAllVenues } from '../../../api/venues';
import { useGetAllPlays } from '../../../api/plays';
import { Play } from '../../../types/Play';
import { Studio } from '../../../types/Studio';
import { Venue, VenueStageConfiguration } from '../../../types/Venue';
import {
  ProductionContribution,
  ProductionContributionType,
} from '../../../types/ProductionContribution';
import { AlertState, QueuedFiles } from '../../../types/Global';
import {
  alertAutoHideDuration,
  GlobalMessages,
  PosterMaxFileSizeInMB,
  PosterSupportedFormat,
} from '../../../utils/globalConfig';
import Loader from '../../../components/Loader';
import { useGetAllInstitutions } from '../../../api/institutions';
import { Institution } from '../../../types/Institution';
import SingleImageUpload from '../../../components/Upload/SingleImageUpload';
import MultiImageUpload, {
  FileInfo,
} from '../../../components/Upload/MultiImageUpload';
import { FileInfo as FilesInfo } from '../../../components/Upload/MultiFileUpload';
import moment from 'moment';
import { UploadFileInfo } from '../../plays/CreatePlays/CreatePlay';
import MultiFileUpload from '../../../components/Upload/MultiFileUpload';
import { UploadMediaType } from '../../../api/utils';

import { v4 as uuidv4 } from 'uuid';
import useErrorHandler from '../../../hooks/useErrorHandler';
import FeaturedProductionContributions from './featured/FeaturedProductionContribution';
import SingleVideoUpload from '../../../components/Upload/SingleVideoUpload';
import { isValidDate, isValidNumber } from '../../../utils/date';

const supportedProductionFileFormats = [
  'pdf',
  'csv',
  'mp4',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'mp3',
];
const bannerPosterFormats = ['jpg', 'jpeg', 'png'];

function getContributionText(contribution: ProductionContribution): string {
  //console.log(contribution)
  const c = contribution;
  switch (contribution.contributionType) {
    case ProductionContributionType.artistic:
      return c.contributionName === 'Custom'
        ? getCustomContributionText(c)
        : `${contribution?.artist?.firstName} ${contribution.artist?.lastName} - ${contribution?.contributionType} - ${contribution?.contributionName}`;
    case ProductionContributionType.musician:
      return c.contributionName === 'Custom'
        ? getCustomContributionText(c)
        : `${contribution?.artist?.firstName} ${contribution.artist?.lastName} - ${contribution?.contributionType} - ${contribution?.contributionName}`;
    default:
      return c.contributionName === 'Custom'
        ? getCustomContributionText(c)
        : `${contribution?.artist?.firstName} ${contribution.artist?.lastName} - ${contribution?.contributionType} - ${contribution?.contributionName}`;
  }
}

function getCustomContributionText(
  contribution: ProductionContribution,
): string {
  return `${contribution.artist.firstName} ${contribution.artist.lastName} - ${contribution.contributionType} - ${contribution.contributionName} - ${contribution.customContributionName}`;
}

export default function CreateProduction() {
  const { id } = useParams();
  const navigate = useNavigate();
  const deleteMutation = useDeleteProduction(id);
  const defaultSideBarState = {
    id: '',
    addProductionArtists: false,
    addFeaturedArtists: false,
    addVenueArtists: false,
    addOrchestrationBooks: false,
    addCharacters: false,
  };
  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
    error: false,
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = CreateProductionsValidation();

  const {
    data: production,
    isSuccess: isProductionLoaded,
    isLoading: isProductionFetchLoading,
  } = useGetProduction(id);
  const { data: plays } = useGetAllPlays();
  const { data: studios } = useGetAllStudios();
  const { data: institutions } = useGetAllInstitutions();
  const { data: venues } = useGetAllVenues();
  const queryClient = useQueryClient();

  const [isSideDrawerOn, setSideDrawerOn] =
    useState<SideBarState>(defaultSideBarState);

  type QueueFileInfo = UploadFileInfo & { file: File };

  const [productionPosterUploadOnQueue, setProductionPosterUploadOnQueue] =
    useState<QueueFileInfo>();
  const [productionBannerUploadOnQueue, setProductionBannerUploadOnQueue] =
    useState<QueueFileInfo>();
  const [productionPosterToBeDeleted, setProductionPosterToBeDeleted] =
    useState<{ mediaId: string; productionId: string }>();
  const [productionBannerToBeDeleted, setProductionBannerToBeDeleted] =
    useState<{ mediaId: string; productionId: string }>();
  const [productionVideoUploadOnQueue, setProductionVideoUploadOnQueue] =
    useState<QueueFileInfo>();
  const [productionVideoToBeDeleted, setProductionVideoToBeDeleted] = useState<{
    mediaId: string;
    productionId: string;
  }>();
  const [
    productionTrailerVideoUploadOnQueue,
    setProductionTrailerVideoUploadOnQueue,
  ] = useState<QueueFileInfo>();
  const [
    productionTrailerVideoToBeDeleted,
    setProductionTrailerVideoToBeDeleted,
  ] = useState<{
    mediaId: string;
    productionId: string;
  }>();
  const [productionPosterDetails, setProductionPosterDetails] = useState();
  const [productionBannerDetails, setProductionBannerDetails] = useState();
  const [productionVideoDetails, setProductionVideoDetails] = useState();
  const [productionTrailerVideoDetails, setProductionTrailerVideoDetails] =
    useState();

  const classes = useStyles();
  const updateMutation = usePutProduction(id);
  const postMutation = usePostProduction();
  const {
    isSuccess: isProductionMetaSuccess,
    isError: isProductionError,
    isLoading: isProductionLoading,
  } = postMutation;

  const {
    isError: isUpdateProductionError,
    isLoading: isUpdateProductionLoading,
    isSuccess: isUpdateProductionSuccess,
  } = updateMutation;

  const [savingLoader, setSavingLoader] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);
  const [carouselImages, setCarouselImages] = useState<FileInfo[]>([]);
  const [productionCarouselOnQueue, setProductionCarouselOnQueue] = useState<
    QueuedFiles[]
  >([]);
  const [productionCarouselToBeDeleted, setProductionCarouselToBeDeleted] =
    useState([]);
  const [productionFiles, setProductionFiles] = useState<FilesInfo[]>([]);
  const [prodFilesOnQueue, setProdFilesOnQueue] = useState<QueuedFiles[]>([]);
  const [prodFilesToBeDeleted, setProdFilesToBeDeleted] = useState([]);
  const { handleError } = useErrorHandler();

  const [updatedMediaCompleted, setUpdatedMediaCompleted] = useState(false);

  const handleUploadComplete = () => {
    setUpdatedMediaCompleted(!updatedMediaCompleted);
  };

  const [initialLoader, setInitialLoader] = useState(true);

  async function updateFiles(production: any) {
    const productionId = production.id || id;

    let productionPosterUploadPromise,
      productionPosterDeletePromise,
      productionBannerUploadPromise,
      productionBannerDeletePromise,
      productionCarouselUploadPromise,
      productionCarouselDeletePromise,
      productionFilesUploadPromise,
      productionFilesDeletePromise,
      productionVideoUploadPromise,
      productionVideoDeletePromise,
      productionTrailerVideoUploadPromise,
      productionTrailerVideoDeletePromise;

    const errors = [];

    if (productionPosterUploadOnQueue) {
      productionPosterUploadPromise = uploadMediaSingle(
        productionId,
        productionPosterUploadOnQueue,
        UploadMediaType.poster,
      ).catch((error) =>
        errors.push(`Error uploading production Poster: ${error.message}`),
      );
    }

    if (productionPosterToBeDeleted) {
      productionPosterDeletePromise = deleteMedia(
        productionId,
        productionPosterToBeDeleted?.mediaId,
      ).catch((error) =>
        errors.push(`Error deleting production Poster: ${error.message}`),
      );
    }

    if (productionBannerUploadOnQueue) {
      productionBannerUploadPromise = uploadMediaSingle(
        productionId,
        productionBannerUploadOnQueue,
        UploadMediaType.banner,
      ).catch((error) =>
        errors.push(`Error uploading production Banner: ${error.message}`),
      );
    }

    if (productionBannerToBeDeleted) {
      productionBannerDeletePromise = deleteMedia(
        productionId,
        productionBannerToBeDeleted?.mediaId,
      ).catch((error) =>
        errors.push(`Error deleting production banner: ${error.message}`),
      );
    }

    if (productionVideoUploadOnQueue) {
      productionVideoUploadPromise = uploadMediaSingleVideo(
        productionId,
        productionVideoUploadOnQueue,
        UploadMediaType.video,
        updateProgress,
      ).catch((error) =>
        errors.push(`Error uploading production video: ${error.message}`),
      );
    }

    if (productionVideoToBeDeleted) {
      productionVideoDeletePromise = deleteMedia(
        productionId,
        productionVideoToBeDeleted?.mediaId,
      ).catch((error) =>
        errors.push(`Error deleting production video: ${error.message}`),
      );
    }

    if (productionTrailerVideoUploadOnQueue) {
      productionTrailerVideoUploadPromise = uploadMediaSingleVideo(
        productionId,
        productionTrailerVideoUploadOnQueue,
        UploadMediaType.trailerVideo,
        updateTrailerVideoProgress,
      ).catch((error) =>
        errors.push(
          `Error uploading production trailer video: ${error.message}`,
        ),
      );
    }

    if (productionTrailerVideoToBeDeleted) {
      productionTrailerVideoDeletePromise = deleteMedia(
        productionId,
        productionTrailerVideoToBeDeleted?.mediaId,
      ).catch((error) =>
        errors.push(
          `Error deleting production trailer video: ${error.message}`,
        ),
      );
    }

    if (productionCarouselOnQueue) {
      productionCarouselUploadPromise = productionCarouselOnQueue.map(
        async (media) => {
          const uploadResponse = await uploadMediaMultiple(
            productionId,
            media,
            UploadMediaType.carousel,
          );
          return uploadResponse;
        },
      );
    }

    if (productionCarouselToBeDeleted) {
      productionCarouselDeletePromise = productionCarouselToBeDeleted.map(
        async (media) => {
          const deleteResponse = await deleteMedia(
            productionId,
            media.mediaDetails.id,
          );
          return deleteResponse;
        },
      );
    }

    if (prodFilesOnQueue) {
      productionFilesUploadPromise = prodFilesOnQueue.map(async (media) => {
        const uploadResponse = await uploadMediaMultiple(
          productionId,
          media,
          UploadMediaType.file,
        );
        return uploadResponse;
      });
    }

    if (prodFilesToBeDeleted) {
      productionFilesDeletePromise = prodFilesToBeDeleted.map(async (media) => {
        const deleteResponse = await deleteMedia(
          productionId,
          media.mediaDetails.id,
        );
        return deleteResponse;
      });
    }

    const [
      productionPosterUploadResponse,
      productionPosterDeleteResponse,
      productionBannerUploadResponse,
      productionBannerDeleteResponse,
      productionCarouselUploadResponse,
      productionCarouselDeleteResponse,
      productionFilesUploadResponse,
      productionFilesDeleteResponse,
      productionVideoUploadResponse,
      productionVideoDeleteResponse,
      productionTrailerVideoUploadResponse,
      productionTrailerVideoDeleteResponse,
    ] = await Promise.all([
      productionPosterUploadPromise,
      productionPosterDeletePromise,
      productionBannerUploadPromise,
      productionBannerDeletePromise,
      productionCarouselUploadPromise,
      productionCarouselDeletePromise,
      productionFilesUploadPromise,
      productionFilesDeletePromise,
      productionVideoUploadPromise,
      productionVideoDeletePromise,
      productionTrailerVideoUploadPromise,
      productionTrailerVideoDeletePromise,
    ]);

    productionCarouselUploadResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading carousel photo ${index + 1}: ${response.error}`,
        );
      }
    });

    productionCarouselDeleteResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error deleting carousel photo ${index + 1}: ${response.error}`,
        );
      }
    });

    productionFilesUploadResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error uploading production file ${index + 1}: ${response.error}`,
        );
      }
    });

    productionFilesDeleteResponse.forEach((response, index) => {
      if (response && response.error) {
        errors.push(
          `Error deleting production file ${index + 1}: ${response.error}`,
        );
      }
    });

    setProdFilesOnQueue([]);
    setProdFilesToBeDeleted([]);
    setProductionCarouselOnQueue([]);
    setProductionCarouselToBeDeleted([]);
    setProductionVideoUploadOnQueue(null);
    setProductionTrailerVideoUploadOnQueue(null);
    setProductionVideoToBeDeleted(null);
    setProductionTrailerVideoToBeDeleted(null);

    return {
      productionPosterUploadResponse,
      productionPosterDeleteResponse,
      productionBannerUploadResponse,
      productionBannerDeleteResponse,
      productionCarouselUploadResponse,
      productionCarouselDeleteResponse,
      productionFilesUploadResponse,
      productionFilesDeleteResponse,
      productionVideoUploadResponse,
      productionVideoDeleteResponse,
      productionTrailerVideoUploadResponse,
      productionTrailerVideoDeleteResponse,
      errors,
    };
  }

  const updateForm = () => {
    reset({
      ...production,
      brandId: production.play ? production.play.brand.id : null,
      playId: production.play?.id,
      selectedPlay: {
        id: production.play?.id,
        label: `${production.play?.licenseName}`,
      },
      studioId: production.studio?.id,
      selectedStudio: {
        id: production.studio?.id,
        label: `${production.studio?.name} - ${production.studio?.id}`,
      },
      venueId: production?.venueId,
      selectedVenue: {
        id:
          production.venueId ?? (production.venue ? production.venue.id : null),
        label: production.venue
          ? `${production.venue.name ?? production.venue.label} - ${
              production.venue.id
            }`
          : null,
      },
      selectedInstitution: {
        id: production.institution ? production.institution.id : null,
        label: production.institution
          ? `${production.institution.name} - ${production.institution.id}`
          : null,
      },
      productionStartDate:
        production.productionStartDate &&
        isValidDate(production.productionStartDate)
          ? moment.utc(production.productionStartDate).format('YYYY-MM-DD')
          : null,
      productionEndDate:
        production.productionEndDate &&
        isValidDate(production.productionEndDate)
          ? moment.utc(production.productionEndDate).format('YYYY-MM-DD')
          : null,
      captureStartDate:
        production.captureStartDate && isValidDate(production.captureStartDate)
          ? moment.utc(production.captureStartDate).format('YYYY-MM-DD')
          : null,
      captureEndDate:
        production.captureEndDate && isValidDate(production.captureEndDate)
          ? moment.utc(production.captureEndDate).format('YYYY-MM-DD')
          : null,
      runtime: production.runtime,
      directorsNotes: production.directorsNotes,
      broadwayWorldSourceId: production.broadwayWorldSourceId,
      productionFiles: production.productionFiles,
    });
  };

  useEffect(() => {
    if (isProductionLoaded && production) {
      setProductionPosterDetails(production.posterMediaDetails);
      setProductionBannerDetails(production.bannerMediaDetails);
      setCarouselImages(production?.carouselPhotos);
      setProductionFiles(production?.productionFiles);
      setProductionVideoDetails(production?.productionVideoDetails);
      setProductionTrailerVideoDetails(
        production?.productionTrailerVideoDetails,
      );
      updateForm();
    }
  }, [isProductionLoaded, updatedMediaCompleted]);

  const onSubmit = async (data: any) => {
    delete data['productionFiles'];
    delete data['carouselPhotos'];
    const formData: any = {
      playId: data.selectedPlay.id,
      studioId: data.selectedStudio.id,
      institutionId:
        data.selectedInstitution != null ? data.selectedInstitution.id : null, // && data.selectedInstitution.id,
      venueId: data.selectedVenue != null ? data.selectedVenue.id : null,
      runtime: data.runtime,
      productionStartDate: data.productionStartDate,
      productionEndDate: data.productionEndDate,
      captureStartDate: data.captureStartDate,
      captureEndDate: data.captureEndDate,
      stageConfiguration: data.stageConfiguration,
      percentSeatsSold: data.percentSeatsSold,
      directorsNotes: data.directorsNotes,
      broadwayWorldSourceId: data.broadwayWorldSourceId,
    };

    try {
      setSavingLoader(true);
      const production = id
        ? await updateMutation.mutateAsync(formData)
        : await postMutation.mutateAsync(formData);

      const fileOpResults = await updateFiles(production);
      handleUploadComplete();

      await queryClient.resetQueries(['selectedProduction']);

      setSavingLoader(false);

      if (fileOpResults.errors.length > 0) {
        setAlertState({
          active: true,
          message:
            'File upload errors:\n• ' + fileOpResults.errors.join('\n• '),
          error: true,
        });
      } else {
        setAlertState({
          active: true,
          message: GlobalMessages.updateProductionSuccessMsg,
        });

        if (!id && production && production.id) {
          reset();
          navigate(`/productions/${production.id}/edit`);
        }
      }
    } catch (e) {
      setSavingLoader(false);
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };

  const handleClose = () => {
    queryClient.removeQueries(['selectedProduction']);
    reset();
    navigate('/productions');
  };

  const handleDeleteProduction = async () => {
    try {
      await deleteMutation.mutateAsync().then(() => {
        setAlertState({
          active: true,
          message: GlobalMessages.deleteVenueSuccessMsg,
        });
        handleClose();
      });
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
        error: true,
      });
    }
  };

  useEffect(() => {
    return () => {
      queryClient.removeQueries(['selectedProduction']);
    };
  }, []);

  const successAlert = isProductionMetaSuccess || isUpdateProductionSuccess;
  const errorAlert = isProductionError || isUpdateProductionError;
  const showAlert =
    (successAlert || errorAlert || alertState.active) && !!alertState.message;

  const [uploadVideoProgress, setUploadVideoProgress] = useState(0);
  const [uploadVideoComplete, setUploadVideoComplete] = useState(false);
  const [uploadTrailerVideoProgress, setUploadTrailerVideoProgress] =
    useState(0);
  const [uploadTrailerVideoComplete, setUploadTrailerVideoComplete] =
    useState(false);

  const updateProgress = (progress) => {
    setUploadVideoComplete(true);
    setUploadVideoProgress(progress);
    if (progress == 100) {
      setUploadVideoComplete(false);
    }
  };

  const updateTrailerVideoProgress = (progress) => {
    setUploadTrailerVideoComplete(true);
    setUploadTrailerVideoProgress(progress);
    if (progress == 100) {
      setUploadTrailerVideoComplete(false);
    }
  };

  return (
    <>
      <Alert
        message={alertState.message}
        open={showAlert}
        severity={alertState.error ? 'error' : 'success'}
        autoHideDuration={alertAutoHideDuration}
        onClose={() => setAlertState({ active: false })}
      />
      <Loader
        isLoading={
          isProductionLoading ||
          isUpdateProductionLoading ||
          savingLoader ||
          isProductionFetchLoading
        }
      >
        {uploadVideoComplete && (
          <Box>
            <Typography>Video</Typography>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress
                value={uploadVideoProgress}
                variant="determinate"
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >{`${Math.round(uploadVideoProgress)}%`}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {uploadTrailerVideoComplete && (
          <Box>
            <Typography>Video</Typography>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress
                value={uploadTrailerVideoProgress}
                variant="determinate"
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="text.secondary"
                >{`${Math.round(uploadTrailerVideoProgress)}%`}</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Loader>
      <Box className={classes.bodyBackground}>
        <DialogHeader
          title={id ? 'Edit Production' : 'Create Production'}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={id && handleDeleteProduction}
          cancel={() => {
            reset();
            navigate('/productions');
          }}
        />
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Paper>
                <Box className={classes.accountInfo}>
                  <Typography variant="h1">Manage Production Media</Typography>
                  <br />
                  <SingleImageUpload
                    title={'Set Poster'}
                    maxFileSizeInMB={PosterMaxFileSizeInMB}
                    validator={(fileExt) =>
                      bannerPosterFormats.includes(fileExt)
                    }
                    initialUpload={
                      production && production.posterURL
                        ? production.posterURL
                        : ''
                    }
                    uploadCallback={async (blob, file) => {
                      setProductionPosterUploadOnQueue({
                        blob,
                        mimeType: file.type,
                        name: file.name,
                        type: 'poster',
                        file: file,
                      });
                    }}
                    deleteCallback={() => {
                      setProductionPosterToBeDeleted(productionPosterDetails);
                    }}
                  />
                  <SingleImageUpload
                    title={'Set Marquee'}
                    initialUpload={
                      production && production.bannerURL
                        ? production.bannerURL
                        : ''
                    }
                    validator={(fileExt) =>
                      PosterSupportedFormat.includes(fileExt)
                    }
                    uploadCallback={async (blob, file) => {
                      setProductionBannerUploadOnQueue({
                        blob,
                        mimeType: file.type,
                        name: file.name,
                        type: 'banner',
                        file: file,
                      });
                    }}
                    deleteCallback={() => {
                      setProductionBannerToBeDeleted(productionBannerDetails);
                    }}
                  />
                  <Divider sx={{ topMargin: '24px', bottomMargin: '24px' }} />
                  <br />
                  <MultiImageUpload
                    id={uuidv4()}
                    title={'Set Production Photos'}
                    message="Select up to 10 photos for your production page."
                    images={carouselImages}
                    validator={(fileExt) =>
                      ['png', 'jpeg', 'jpg'].includes(fileExt)
                    }
                    uploadCallback={(fileInfo, fileType) => {
                      setCarouselImages((files) => [...files, fileInfo]);
                      setProductionCarouselOnQueue((existingFiles) => [
                        ...existingFiles,
                        { fileInfo, fileType },
                      ]);
                    }}
                    deleteCallback={(file) => {
                      setCarouselImages([
                        ...carouselImages.filter(
                          (f) => f?.mediaDetails.id !== file?.mediaDetails.id,
                        ),
                      ]);
                      setProductionCarouselToBeDeleted((existingFiles) => [
                        ...existingFiles,
                        file,
                      ]);
                    }}
                  />
                  <Divider sx={{ margin: '24px' }} />
                  <MultiFileUpload
                    id={uuidv4()}
                    title={'Manage Production Files'}
                    files={productionFiles}
                    validator={(fileExt, sizeInMB) =>
                      supportedProductionFileFormats.includes(fileExt) &&
                      sizeInMB < 100
                    }
                    uploadCallback={(fileInfo, fileType) => {
                      setProductionFiles((files) => [...files, fileInfo]);
                      setProdFilesOnQueue((existingFiles) => [
                        ...existingFiles,
                        { fileInfo, fileType },
                      ]);
                    }}
                    deleteCallback={(file) => {
                      setProductionFiles([
                        ...productionFiles.filter(
                          (f) => f.mediaDetails.id !== file.mediaDetails.id,
                        ),
                      ]);
                      setProdFilesToBeDeleted((existingFiles) => [
                        ...existingFiles,
                        file,
                      ]);
                    }}
                  />
                  <Divider sx={{ marginBottom: '24px' }} />
                  <SingleVideoUpload
                    title={'Upload Production Video'}
                    initialUpload={production?.videoURL}
                    uploadCallback={(blob, file) =>
                      setProductionVideoUploadOnQueue({
                        blob,
                        mimeType: file.type,
                        name: file.name,
                        type: UploadMediaType.video,
                        file: file,
                      })
                    }
                    deleteCallback={() => {
                      setProductionVideoToBeDeleted(productionVideoDetails);
                    }}
                  />
                  <Divider sx={{ marginBottom: '24px' }} />
                  <SingleVideoUpload
                    title={'Upload Trailer Video'}
                    initialUpload={production?.trailerVideoURL}
                    uploadCallback={(blob, file) =>
                      setProductionTrailerVideoUploadOnQueue({
                        blob,
                        mimeType: file.type,
                        name: file.name,
                        type: UploadMediaType.video,
                        file: file,
                      })
                    }
                    deleteCallback={() =>
                      setProductionTrailerVideoToBeDeleted(
                        productionTrailerVideoDetails,
                      )
                    }
                  />
                  <Divider sx={{ marginBottom: '24px' }} />
                  <Typography variant="h1">
                    Manage Production Details
                  </Typography>

                  <Box sx={{ display: 'flex', mt: 3 }}>
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        label={'License Name'}
                        placeholder="License Name"
                        name="selectedPlay"
                        control={control}
                        options={((plays as Play[]) || [])
                          .map((play: Play) => {
                            // if (play.licenseName == null) {
                            //   return null
                            // }

                            return {
                              id: play.id,
                              label: `${play.licenseName} - ${play.id}`,
                            };
                          })
                          .filter((e) => {
                            return e.label !== 'null';
                          })}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mt: 3 }}>
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        label={'Studio'}
                        placeholder="Selected Studio"
                        name="selectedStudio"
                        control={control}
                        options={((studios as Studio[]) || []).map(
                          (studio: Studio) => {
                            return {
                              id: studio.id,
                              label: `${studio.name} - ${studio.city} - ${studio.state} - ${studio.id}`,
                            };
                          },
                        )}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mt: 3 }}>
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        label={'Institution'}
                        placeholder="Selected Institution"
                        name="selectedInstitution"
                        control={control}
                        options={(institutions || []).map(
                          (institution: Institution) => ({
                            id: institution.id,
                            label: `${institution.name} - ${
                              institution.city
                            } - ${institution.state ?? institution.country} - ${
                              institution.id
                            }`,
                          }),
                        )}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mt: 3 }}>
                    <Box sx={{ width: '100%' }}>
                      <Autocomplete
                        label={'Venue'}
                        placeholder="Selected Venue"
                        name="selectedVenue"
                        control={control}
                        options={((venues as Venue[]) || []).map(
                          (venue: Venue) => {
                            return {
                              id: venue.id,
                              label: `${venue.name} - ${venue.id}`,
                            };
                          },
                        )}
                      />
                    </Box>
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="runtime"
                      label="Runtime (minutes)"
                      type={'number'}
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="productionStartDate"
                      label="Production Start Date"
                      type="date"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="productionEndDate"
                      label="Production End Date"
                      type="date"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="captureStartDate"
                      label="Capture Start Date"
                      type="date"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="captureEndDate"
                      label="Capture End Date"
                      type="date"
                      control={control}
                    />
                  </Box>
                  <Box mt={3}>
                    <Select
                      options={(
                        Object.keys(VenueStageConfiguration) as Array<
                          keyof typeof VenueStageConfiguration
                        >
                      ).map((key) => VenueStageConfiguration[key])}
                      name="stageConfiguration"
                      label="Stage Configuration"
                      control={control}
                      placeholder={'Stage Configuration'}
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="percentSeatsSold"
                      label="Percent Seats Sold"
                      control={control}
                      type="number"
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      maxRows={4}
                      multiline={true}
                      placeholder="Director's Notes"
                      onChange={(e) => console.log(e.target.value)}
                      label="Director's Notes"
                      control={control}
                      name="directorsNotes"
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      name="broadwayWorldSourceId"
                      label="Broadway World Source ID"
                      control={control}
                    />
                  </Box>
                </Box>
                {id && (
                  <Box mt={'16px'} p={'32px'}>
                    <Details
                      title="Production Contributions"
                      onAddButton={() =>
                        setSideDrawerOn({
                          ...defaultSideBarState,
                          addProductionArtists:
                            !isSideDrawerOn.addProductionArtists,
                        })
                      }
                    >
                      {!isProductionLoaded ? (
                        <div>Loading Characters...</div>
                      ) : (
                        production &&
                        production.contributions?.map(
                          (contribution: ProductionContribution) => (
                            <>
                              <Stack
                                key={uuidv4()}
                                display="flex"
                                direction="row"
                                style={{ marginBottom: '24px' }}
                              >
                                <ListItem key={uuidv4()}>
                                  {getContributionText(contribution)}
                                </ListItem>
                                <Button
                                  label="Edit"
                                  variant={'outlined'}
                                  color={'secondary'}
                                  onClick={() =>
                                    setSideDrawerOn({
                                      ...defaultSideBarState,
                                      addProductionArtists:
                                        !isSideDrawerOn.addProductionArtists,
                                      id: `${contribution.id}`,
                                    })
                                  }
                                />
                              </Stack>
                            </>
                          ),
                        )
                      )}

                      {production && id && (
                        <Box my={3}>
                          <FeaturedProductionContributions
                            contributions={
                              production?.featuredContributors || []
                            }
                            productionId={id}
                          />
                        </Box>
                      )}
                    </Details>
                  </Box>
                )}

                <ProductionsSideDrawers
                  sideDrawerState={isSideDrawerOn}
                  id={isSideDrawerOn.id}
                  defaultSideBarState={defaultSideBarState}
                  currentState={(state: any) => setSideDrawerOn(state)}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
