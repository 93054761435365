import React, { useEffect, useState } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import {
  Alert,
  Autocomplete,
  Paper,
  Select,
  SideDrawer,
  TextField,
} from '../../../components';
import DialogHeader from '../../common/DialogHeader';
import { AddProductionContributionValidation } from './ProductionsValidationSchema';
import { useStyles } from '../styles';
import { ProductionArtistsProps, SideBarArtistsState } from '../types';
import {
  deleteMedia,
  uploadMediaMultiple,
  useDeleteProductionContribution,
  usePostProductionContribution,
  usePutProductionContribution,
} from '../../../api/productionContribution';
import { Production } from '../../../types/Production';
import { useQueryClient } from 'react-query';
import { Artist } from '../../../types/Artist';
import AddIcon from '@mui/icons-material/Add';
import AddArtists from '../../plays/CreatePlays/AddArtists';
import { useGetAllOrchestrationBooksForPlay } from '../../../api/orchestrationBooks';
import { useGetAllCharacters } from '../../../api/characters';
import { Character } from '../../../types/Character';
import {
  AdministrativeProductionContribution,
  ArtisticProductionContribution,
  CrewProductionContribution,
  FacultyProductionContribution,
  MusicianProductionContribution,
  PerformerProductionContribution,
  ProductionContributionType,
  StudioProductionContribution,
  VenueProductionContribution,
} from '../../../types/ProductionContribution';
import Loader from '../../../components/Loader';
import {
  alertAutoHideDuration,
  GlobalMessages,
} from '../../../utils/globalConfig';
import { AlertState, QueuedFiles } from '../../../types/Global';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import MultiFileUpload, {
  FileInfo,
} from '../../../components/Upload/MultiFileUpload';

import { getProductionContribution } from '../../../api/production';
import { UploadMediaType } from '../../../api/utils';
import { LazyAutocomplete } from '../../../components/LazyAutocomplete';
import axios from '../../../config/http-common';

export default function AddProductionContributions(
  props: ProductionArtistsProps,
) {
  const { onClose, id } = props;
  const [alertState, setAlertState] = useState<AlertState>({
    active: false,
    message: '',
  });
  const defaultSideBarState = {
    addProductionArtists: false,
    addOrchestrationBooks: false,
    addCharacters: false,
  };
  const [isSideDrawerOn, setSideDrawerOn] =
    useState<SideBarArtistsState>(defaultSideBarState);
  const [isCustomContributionType, setIsCustomContributionType] =
    useState(false);

  const queryClient = useQueryClient();

  const classes = useStyles();
  const { handleSubmit, control, reset, watch, setValue } =
    AddProductionContributionValidation();

  // Contribution Types and Names
  const watchContributionType: ProductionContributionType =
    watch('contributionType');
  const watchContributionName: any = watch('contributionName');
  const [contributionNameOptions, setContributionNameOptions] = useState([]);
  const [customContributionName, setCustomContributionName] = useState('');
  const [selectedArtistId, setSelectedArtistId] = useState('');
  const [filesOnQueue, setFilesOnQueue] = useState<QueuedFiles[]>([]);
  const [contributionId, setContributionId] = useState(id);
  const [productionContributionFiles, setProductionContributionFiles] =
    useState<FileInfo[]>([]);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
  const [selectedArtistOnEdit, setSelectedArtistOnEdit] = useState<any>();

  useEffect(() => {
    let selectedEnum: any = null;
    switch (watchContributionType) {
      case ProductionContributionType.administrative:
        selectedEnum = AdministrativeProductionContribution;
        break;
      case ProductionContributionType.artistic:
        selectedEnum = ArtisticProductionContribution;
        break;
      case ProductionContributionType.crew:
        selectedEnum = CrewProductionContribution;
        break;
      case ProductionContributionType.faculty:
        selectedEnum = FacultyProductionContribution;
        break;
      case ProductionContributionType.musician:
        selectedEnum = MusicianProductionContribution;
        break;
      case ProductionContributionType.performer:
        selectedEnum = PerformerProductionContribution;
        break;
      case ProductionContributionType.studio:
        selectedEnum = StudioProductionContribution;
        break;
      case ProductionContributionType.venue:
        selectedEnum = VenueProductionContribution;
        break;
    }

    if (watchContributionType) {
      setContributionNameOptions(
        (Object.keys(selectedEnum) as Array<keyof typeof selectedEnum>)
          .map((key) => selectedEnum[key])
          .sort(),
      );
    } else {
      setContributionNameOptions([]);
    }

    if (watchContributionName == 'Custom') {
      setCustomContributionName('');
    }
  }, [watchContributionType, watchContributionName, customContributionName]);

  const production: Production = queryClient.getQueryData([
    'selectedProduction',
  ]);

  const updateMutation = usePutProductionContribution(production?.id, id);
  const postMutation = usePostProductionContribution(production?.id);
  const deleteMutation = useDeleteProductionContribution(production?.id, id);
  const { data: characters } = useGetAllCharacters(production?.playId);
  const { data: orchestrationBooks } = useGetAllOrchestrationBooksForPlay(
    production?.playId,
  );
  const {
    isError: postError,
    isLoading: postLoading,
    isSuccess: postSuccess,
  } = postMutation;
  const {
    isError: updateError,
    isLoading: updateLoading,
    isSuccess: updateSuccess,
  } = updateMutation;
  const {
    isError: deleteError,
    isSuccess: deleteSuccess,
    isLoading: deleteLoading,
  } = deleteMutation;

  const [savingLoader, setSavingLoader] = useState(false);

  async function getProductContributionInfo(contributionId) {
    return await getProductionContribution(production?.id, contributionId);
  }

  useEffect(() => {
    async function init() {
      const productionContribution = await getProductContributionInfo(id);
      if (id && productionContribution?.artist) {
        setSelectedArtistOnEdit(productionContribution?.artist);
      }
      if (productionContribution.contributionFileURLs) {
        setProductionContributionFiles(
          productionContribution.contributionFileURLs,
        );
      }
    }

    init();
    if (id) {
      const contribution = production.contributions.find(
        (val: any) => val.id === id,
      );
      setContributionId(contribution.id);
      let specificContribution = {};
      if (
        contribution.contributionType === ProductionContributionType.performer
      ) {
        if (contribution.character != null) {
          specificContribution = {
            contributionName: contribution.character.name,
            customContributionName: contribution.customContributionName,
            characterId: contribution.character.id,
            selectedCharacter: {
              id: contribution.character.id,
              label: contribution.character.name,
            },
          };
        } else {
          specificContribution = {
            id: contribution.contributionName,
            label: contribution.contributionName,
            customContributionName: contribution.customContributionName,
          };
        }
      } else {
        specificContribution = {
          id: contribution.contributionName,
          label: contribution.contributionName,
          customContributionName: contribution.customContributionName,
        };
      }
      reset({
        ...contribution,
        contributionName: {
          id: contribution.contributionName,
          label: contribution.contributionName,
          customContributionName: contribution.customContributionName,
        },
        artistId: contribution?.artist?.id,
        artist: {
          id: contribution?.artist?.id,
          label: `${contribution?.artist?.firstName} ${contribution?.artist?.lastName}`,
        },
        ...specificContribution,
      });
    }
  }, [id, orchestrationBooks]);

  async function updateFiles(contributionId: string) {
    let contributionFilesUploadPromise, contributionFilesDeletePromise;

    if (filesOnQueue) {
      contributionFilesUploadPromise = filesOnQueue.map(async (file) => {
        const uploadResponse = await uploadMediaMultiple(
          production.id,
          contributionId,
          file,
          UploadMediaType.file,
        );
        return uploadResponse;
      });
    }

    if (filesToBeDeleted) {
      contributionFilesDeletePromise = filesToBeDeleted.map(async (file) => {
        const deleteResponse = await deleteMedia(
          production.id,
          contributionId,
          file.mediaDetails.id,
        );
      });
    }

    const [contributionFilesUploadResponse, contributionFilesDeleteResponse] =
      await Promise.all([
        contributionFilesUploadPromise,
        contributionFilesDeletePromise,
      ]);

    return {
      contributionFilesUploadResponse,
      contributionFilesDeleteResponse,
    };
  }

  const onSubmit = async (data: any) => {
    try {
      setSavingLoader(true);
      const formData = {
        ...data,
        contributionName:
          watchContributionType == ProductionContributionType.performer
            ? data.selectedCharacter?.label
            : data.contributionName?.label,
        customContributionName: data.customContributionName ?? null,
        artistId: data.artist.id,
        productionId: production?.id,
      };
      console.log(formData);
      delete formData.id; // to avoid contribution id override
      if (watchContributionType == ProductionContributionType.performer) {
        formData.contributionName = data.selectedCharacter.label;
        formData.characterId = data.selectedCharacter.id;
      }
      if (watchContributionType !== ProductionContributionType.musician) {
        formData.orchestrationBookId = null;
      }
      if (watchContributionName == 'Custom') {
        formData.customContributionName = data.customContributionName ?? null;
      }
      try {
        const contribution = id
          ? await updateMutation.mutateAsync(formData)
          : await postMutation.mutateAsync(formData);
        setAlertState({
          active: true,
          message: id
            ? GlobalMessages.updateProductionSuccessMsg
            : GlobalMessages.addProductionSuccessMsg,
        });
        const contributionId = id || contribution.id;
        const fileOps = await updateFiles(contributionId);
        setSavingLoader(false);
        handleClose();
      } catch (e) {
        setAlertState({
          active: true,
          message: GlobalMessages.errorMsg,
        });
        setSavingLoader(false);
      }
    } catch (error) {
      console.log(error);
      setSavingLoader(false);
    }
  };

  const handleDeleteContribution = async () => {
    try {
      await deleteMutation.mutateAsync();
      await handleClose();
    } catch (e) {
      setAlertState({
        active: true,
        message: GlobalMessages.errorMsg,
      });
    }
  };

  const handleClose = () => {
    queryClient.invalidateQueries(['selectedProduction']).then(() => {
      reset();
      onClose();
    });
  };

  if (postSuccess || updateSuccess || deleteSuccess) {
    return (
      <Alert
        message={alertState.message}
        open={alertState.active}
        autoHideDuration={alertAutoHideDuration}
        onClose={() => setAlertState({ active: false })}
      />
    );
  }

  return (
    <>
      {(postLoading || updateLoading || deleteLoading || savingLoader) && (
        <Loader
          isLoading={
            postLoading || updateLoading || deleteLoading || savingLoader
          }
        />
      )}
      {(postError || updateError || deleteError) && (
        <Alert
          message={alertState.message}
          open={alertState.active}
          severity="error"
          autoHideDuration={alertAutoHideDuration}
          onClose={() => setAlertState({ active: false })}
        />
      )}
      <Box>
        <DialogHeader
          title={id ? 'Edit' : 'Add'}
          handleSave={handleSubmit(onSubmit)}
          handleDelete={id && handleDeleteContribution}
          cancel={() => handleClose()}
        />
        <Box className={classes.content}>
          <Paper>
            <Box className={classes.subContent}>
              <MultiFileUpload
                title="Production Contribution Files"
                files={productionContributionFiles}
                uploadCallback={(fileInfo, fileType) => {
                  setProductionContributionFiles((files) => [
                    ...files,
                    fileInfo,
                  ]);
                  setFilesOnQueue((existingFiles) => [
                    ...existingFiles,
                    { fileInfo, fileType },
                  ]);
                }}
                deleteCallback={(file) => {
                  setProductionContributionFiles((existingFiles) =>
                    existingFiles.filter(
                      (f) => f?.mediaDetails.id !== file?.mediaDetails.id,
                    ),
                  );
                  setFilesToBeDeleted((existingFiles) => [
                    ...existingFiles,
                    file,
                  ]);
                }}
              />
              <Typography variant="h1">
                Production Contribution Details
              </Typography>
              <Divider style={{ marginBottom: '24px' }} />
              <Box mt={3}>
                <Select
                  label="Contribution Type"
                  placeholder="Contribution Type"
                  control={control}
                  name="contributionType"
                  options={(
                    Object.keys(ProductionContributionType) as Array<
                      keyof typeof ProductionContributionType
                    >
                  )
                    .map((key) => ProductionContributionType[key])
                    .sort()}
                />
              </Box>
              {watchContributionType !==
                ProductionContributionType.performer && (
                <Box mt={3}>
                  <Autocomplete
                    label="Contribution Name"
                    placeholder="Type a Contribution Name"
                    name="contributionName"
                    control={control}
                    options={(
                      contributionNameOptions as ProductionContributionType[]
                    ).map((pc: ProductionContributionType) => ({
                      id: pc,
                      label: pc,
                      customContributionName: null,
                    }))}
                    getSelectedOption={(field) => {
                      if (field.value != null) {
                        setIsCustomContributionType(
                          field.value.label == 'Custom',
                        );
                      }
                    }}
                    sx={{ marginTop: '30px' }}
                  />
                </Box>
              )}
              {isCustomContributionType && (
                <Box mt={3}>
                  <TextField
                    label="Custom Contribution Name"
                    control={control}
                    name="customContributionName"
                  />
                </Box>
              )}
              <Box mt={3} sx={{ display: 'flex' }}>
                <Box style={{ width: '100%' }}>
                  <LazyAutocomplete
                    label="Artist"
                    placeholder="Search Artist..."
                    name="artist"
                    control={control}
                    handleSelect={(artist) => {
                      setValue('artist', artist);
                    }}
                    getOptionsList={async function getOptionsList(searchText) {
                      return await axios.post(
                        `artists/lite?searchText=${searchText}`,
                      );
                    }}
                    getOptionLabel={(option: Artist) =>
                      `${option.firstName} ${option.lastName} - ${option.id}`
                    }
                  />
                  <CopyToClipboardButton id={selectedArtistId} />
                </Box>
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="addArtists"
                    component="div"
                    sx={{ marginTop: '12px' }}
                    onClick={() =>
                      setSideDrawerOn({
                        ...defaultSideBarState,
                        addProductionArtists:
                          !isSideDrawerOn.addProductionArtists,
                      })
                    }
                  >
                    <Typography variant="h6">Add New Artist</Typography>
                    <AddIcon />
                  </IconButton>
                  <SideDrawer
                    isActive={isSideDrawerOn.addProductionArtists}
                    toggleDrawer={() =>
                      setSideDrawerOn({
                        ...defaultSideBarState,
                        addProductionArtists:
                          !isSideDrawerOn.addProductionArtists,
                      })
                    }
                    styles={{ width: '650px' }}
                  >
                    <AddArtists
                      onClose={(artist) => {
                        if (artist) {
                          setValue('artist', {
                            id: artist.id,
                            label: `${artist.firstName} ${artist.lastName} - ${artist.id}`,
                          });
                        }
                        setSideDrawerOn({
                          ...defaultSideBarState,
                          addProductionArtists:
                            !isSideDrawerOn.addProductionArtists,
                        });
                      }}
                    />
                  </SideDrawer>
                </Box>
              </Box>
              <Box
                mt={3}
                sx={{
                  display:
                    watchContributionType ===
                    ProductionContributionType.performer
                      ? 'flex'
                      : 'none',
                }}
              >
                {watchContributionType ===
                  ProductionContributionType.performer && (
                  <Box style={{ width: '100%' }}>
                    <Autocomplete
                      label="Play Character"
                      placeholder="Select Character"
                      name="selectedCharacter"
                      control={control}
                      options={((characters as Character[]) || []).map(
                        (character) => ({
                          id: character.id,
                          label: `${character.name}`,
                        }),
                      )}
                    />
                  </Box>
                )}
                <Typography>
                  {watchContributionName &&
                    watchContributionName?.id == 'Custom'}
                </Typography>
              </Box>
              <Box
                mt={3}
                sx={{
                  display:
                    watchContributionName &&
                    watchContributionName?.id == 'Custom'
                      ? 'flex'
                      : 'none',
                }}
              >
                {watchContributionName &&
                  watchContributionName?.id == 'Custom' && (
                    <Box style={{ width: '100%' }}>
                      <TextField
                        maxRows={2}
                        placeholder="If you do not see your contribution type, please add it here."
                        label="Custom Contribution Name"
                        control={control}
                        name="customContributionName"
                      />
                    </Box>
                  )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}
